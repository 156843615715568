/* eslint-disable eqeqeq */
import React, { useEffect, useContext, useState } from 'react';
import { useRouteMatch, useHistory } from 'react-router';
import { Switch, Route, Redirect } from 'react-router-dom';
import BrandingContextProvider from './contexts/BrandingContext';
import LocationContextProvider from './contexts/LocationContext';
import Routes from './Routes';
import isMobile from './helpers/detectMobile';
import MobileOnly from './components/MobileOnly.js';
import './assets/icons/fontawesome-pro-5.11.2-web/css/all.css';
import Api from './api';
import routeNames from './constants/routeNames';
import CookieBar from './components/CookieBar/CookieBar';
import { LoadingContext } from './contexts/LoadingContext';
import { ContentContext } from './contexts/ContentContext';
import colorFunctions from './helpers/colorFunctions';
import { Helmet } from 'react-helmet';
import routeHelper from './helpers/routeHelper';
const ORIGINS = ['twitter', 'facebook', 'pinterest', 'email', 'instapaper', 'whatsapp'];


const FONTS = {
    "font_title": "'Bebas Neue', sans-serif",
    "font_body": "'Source Sans Pro', sans-serif",
    "font_button": "'Bebas Neue', sans-serif"
}

function App() {
    const match = useRouteMatch(`${routeNames.HOME}:origin/`);
    const [configLoading, setConfigLoading] = useState(true);
    const history = useHistory();
    const { content, setContent } = useContext(ContentContext);
    const { loading, setLoading } = useContext(LoadingContext)
    const metadata = content?.global_settings?.metadata;
    const emailSettings = content?.email?.settings;
    useEffect(() => {

        (async () => {
            setConfigLoading(true);

            await updateContent();

            await checkCampaign();
            await checkOrigin();
            if (navigator.userAgent.indexOf('iPhone') > 0) {
                document.body.classList += 'iPhone ';
            } else if (navigator.userAgent.indexOf('Mac') > 0) {
                document.body.classList += 'mac-os ';
            }
            setConfigLoading(false);
        })();


        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        updateBrand();
        // eslint-disable-next-line
    }, [content]);

    const checkCampaign = async () => {
        let checkCampaignResponse = await Api.checkCampaign();
        if (!checkCampaignResponse.result) {
            // let route = routeNames.ALREADY_REDEEMED;
            // if (
            //     checkCampaignResponse?.error?.type == 102 ||
            //     checkCampaignResponse?.error?.type == 103
            // ) {
            //     route = routeNames.PROMOTION_CLOSED;
            // }

            // history.push(route, {
            //     type: checkCampaignResponse?.error?.type,
            //     title: checkCampaignResponse?.error?.message?.title,
            //     subtitle: checkCampaignResponse?.error?.message?.subtitle,
            // });

            routeHelper(checkCampaignResponse?.error?.type, content, history);
        }
    };


    const checkOrigin = async () => {
        let savedOrigin = sessionStorage.getItem('ORIGIN');
        if (savedOrigin != null) return;

        let origin = 'organic';
        if (match && ORIGINS.indexOf(match.params.origin) !== -1) origin = match.params.origin;
        sessionStorage.setItem('ORIGIN', origin);
        await Api.sendOrigin(origin);
    };

    const updateBrand = () => {
        const alphaValues = [
            { ex: '65', value: 0.65 },
            { ex: '08', value: 0.08 },
            { ex: '5', value: 0.5 },
        ];
        if (!content) return;

        if (content && content.global_settings && content.global_settings.colors) {
            let colors = Object.keys(content.global_settings.colors);
            colors.forEach(key => {
                document.documentElement.style.setProperty(
                    `--${key}`,
                    content?.global_settings.colors[key]
                );
                if (key == "primary_opacity") {
                    document.documentElement.style.setProperty(
                        `--primary_color_opacity`,
                        colorFunctions.hexToRgba(content?.global_settings.colors["primary"], content?.global_settings.colors[key])
                    );
                }
                alphaValues.forEach(alpha => {
                    document.documentElement.style.setProperty(
                        `--${key}_alpha_${alpha.ex}`,
                        colorFunctions.hexToRgba(content?.global_settings.colors[key], alpha.value)
                    );
                });

            });
        }

        if (content && content.global_settings && content.global_settings.fonts) {
            let fonts = Object.keys(content.global_settings.fonts);
            fonts.forEach(key => {
                document.documentElement.style.setProperty(
                    `--${key}`,
                    FONTS[key]
                );
            });
        }
    };




    const updateContent = async () => {
        const campaignContentResponse = await Api.getCampaignContent();
        if (campaignContentResponse && campaignContentResponse.result) {
            setContent(campaignContentResponse.data);
        }
    };

    return (
        <BrandingContextProvider>
            <LocationContextProvider>

                <Helmet>
                    <title>{metadata?.title}</title>
                    <meta name="description" content={`${metadata?.description}`} />

                    <meta property="og:title" content={`${metadata?.title}`} />
                    <meta property="og:description" content={`${metadata?.description}`} />
                    <meta property="og:image" content={`${metadata?.openGraphImage}`} />
                    <meta property="og:url" content={`${content?.campaignDomain}`} />

                    <link
                        rel="icon"
                        href={
                            metadata?.favicon ||
                            'https://upload.wikimedia.org/wikipedia/commons/4/48/BLANK_ICON.png'
                        }
                    />
                </Helmet>
                <div className={`site-wrapper`} >
                    <div className={`loading-wrapper ${(!configLoading || !loading) && 'done'}`}>
                        <div className="loading-spinner" />
                    </div>
                    {!configLoading && (<Switch>
                        {Routes.map(r => (
                            <Route
                                key={r.name}
                                path={r.path}
                                exact={r.exact}
                                component={
                                    isMobile || r.availableOnDesktop ? r.component : MobileOnly
                                }
                            />
                        ))}
                        <Redirect to={routeNames.HOME} />
                    </Switch>)}

                    <CookieBar />
                </div>
            </LocationContextProvider>
        </BrandingContextProvider>
    );
}

export default App;
