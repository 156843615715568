import React, { useState, useEffect, useContext } from 'react';
import { useHistory, Link } from 'react-router-dom';
import PageSlug from '../../components/PageSlug/';
import RegisterForm from '../../components/RegisterForm/Index';
import {LoadingContext} from '../../contexts/LoadingContext';
import './SignUpPage.css';
import routeNames from '../../constants/routeNames';
import { ContentContext } from '../../contexts/ContentContext';
import Brand from '../../components/Brand';
import Footer from '../../components/Footer';
import SocialShares from '../../components/SocialShares/SocialShares';
import WhatsappButton from '../../components/WhatsappButton';
import routeHelper from '../../helpers/routeHelper';

function SignUpPage({ location }) {
    const history = useHistory();
    const {loading,setLoading}= useContext(LoadingContext);
    const [data, setData] = useState(location.state);
    const [registerSuccess, setRegisterSuccess] = useState(null);
    const { content } = useContext(ContentContext);
    const s = content?.pages?.register?.settings;
    const c = content?.pages?.register?.content;
    const generic = content?.pages?.register?.generic;
    const g = content?.global_settings?.images;
    const showSocials = s?.footer_social;
    const whatsapp = s?.whatsapp;

    useEffect(() => {
        window.scrollTo({ top: 0 });
        setLoading(true);
        if (!location.state) {
            setLoading(true);
            history.push(routeNames.HOME);
        }
        setLoading(false);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (registerSuccess?.result) {
            // setLoading(true);
            history.push(routeNames.VENUE, data);
        } else if (registerSuccess?.error?.type) {
            routeHelper(registerSuccess?.error?.type,content,history,data,registerSuccess);
            // history.push(routeNames.ALREADY_REDEEMED, {
            //     type: registerSuccess?.error?.type,
            //     registerData: registerSuccess,
            //     data,
            // });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [registerSuccess]);

    return (
        <div
            className={`${s?.bg && g?.bg ? 'has-bg' : ''} page-wrapper signUpPage`}
            style={{ backgroundImage: s?.bg && g?.bg && `url(${g?.bg})` }}
        >
            {s?.header && g?.header && <Brand image={g?.header} />}

            <main>
                {registerSuccess === null && (
                    <>
                        {s?.logo && g?.logo && (
                            <section className="campaign-logo">
                                <Link to={routeNames.HOME} className="brand-logo">
                                    <img
                                        className="header-title-image"
                                        alt="scale"
                                        src={g?.logo}
                                    />
                                </Link>
                            </section>
                        )}

                        <section
                            className={`main-content ${s?.bg && g?.bg ? 'transparent-container' : ''
                                }`}
                        >
                            <PageSlug subtitle={c?.title?.value} />

                            <RegisterForm
                                content={generic}
                                formContent={c}
                                data={data}
                                setData={setData}
                                setRegisterSuccess={setRegisterSuccess}
                            />
                            <section className="resend-email-link">
                                <Link to={routeNames.RESEND_EMAIL} dangerouslySetInnerHTML={{__html : c?.already_signed_up?.value}}></Link>

                            </section>

                        </section>
                    </>
                )}



                {registerSuccess && registerSuccess.error === 'User exists' && (
                    <section className="text-center card section-padding-small mb0">
                        <PageSlug title={c?.already_signed_up?.value} />
                    </section>
                )}

                <section className={'transparent-container ' + (!showSocials && !whatsapp ? 'hidden' : '')}>
                    {showSocials && (<SocialShares></SocialShares>)}
                    {whatsapp && <WhatsappButton />}
                </section>

                {s?.footer && (
                    <Footer className={`${s?.bg && g?.bg ? 'transparent-container' : ''}`} />
                )}
            </main>
        </div>
    );
}

export default SignUpPage;
