import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import CheckBox from '../CheckBox/';
import { useForm } from 'react-hook-form';
import './RegisterForm.css';
import Api from '../../api';
import routeNames from '../../constants/routeNames';

function RegisterForm({ data, setData, setRegisterSuccess, content, formContent }) {
    const { register, handleSubmit, errors } = useForm();
    const submitBtn = useRef(null);

    const onSubmit = async formData => {
        submitBtn.current.classList.add('loading');
        const combinedData = { ...formData, ...data };
        const origin = sessionStorage.getItem('ORIGIN');
        combinedData.socialType = origin;
        setData(combinedData);
        const checkUser = await Api.checkUser(combinedData.email);
        submitBtn.current.classList.remove('loading');
        setRegisterSuccess(checkUser);
    };

    return (
        <section className="register-form">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="input-wrapper">
                    <input
                        tabIndex={1}
                        type="text"
                        name="firstName"
                        placeholder={content?.name_label?.value}
                        ref={register({
                            required: `${content?.name_label_error?.value}`,
                            maxLength: 20,
                        })}
                    />
                    {errors.firstName && <p className="form-validation-error">{errors.firstName.message}</p>}
                </div>
                <div className="input-wrapper">
                    <input
                        tabIndex={2}
                        type="text"
                        name="lastName"
                        placeholder={content?.last_name_label?.value}
                        ref={register({
                            required: `${content?.last_name_label_error?.value}`,
                            maxLength: 20,
                        })}
                    />
                </div>
                {errors.lastName && <p className="form-validation-error" >{errors.lastName.message}</p>}

                <div className="input-wrapper wide-desktop">
                    <input
                        tabIndex={3}
                        type="email"
                        name="email"
                        placeholder={content?.email_label?.value}
                        ref={register({
                            required: `${content?.email_label_error_required?.value}`,
                            pattern: {
                                value: /^[a-zA-Z0-9.!#$%&’*=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                message: `${content?.email_label_error_invalid?.value}`,
                            },
                        })}
                    />
                </div>
                {errors.email && <p className="form-validation-error">{errors.email.message}</p>}

                <div className="checkboxes-wrapper">
                    <div className="input-wrapper wide-desktop">
                        <CheckBox
                            tabIndex={5}
                            name="terms"
                            ref={register({
                                required: `${content?.terms_label_error?.value}`,
                            })}>                            <span dangerouslySetInnerHTML={{ __html: formContent?.terms_label?.value }}></span>
                        </CheckBox>
                        {formContent?.terms_description?.value != "" && (
                            <small className='description-box' dangerouslySetInnerHTML={{ __html: formContent?.terms_description?.value }}></small>
                        )}
                    </div>
                    {errors.terms && <p className="form-error" >{errors.terms.message}</p>}

                    <div className="input-wrapper wide-desktop">
                        {formContent?.offers_label && formContent?.offers_label.value !== '' && (
                            <CheckBox tabIndex={6} name="subscription" ref={register}>
                                <span dangerouslySetInnerHTML={{ __html: formContent?.offers_label?.value }}></span>
                            </CheckBox>)}
                        {formContent?.offers_description?.value != "" && (
                            <small className='description-box' dangerouslySetInnerHTML={{ __html: formContent?.offers_description?.value }}></small>
                        )}
                    </div>

                </div>
                <div className="input-wrapper input-block">
                    <button ref={submitBtn} type="submit" className="btn btn-brand btn-wide">
                        <span dangerouslySetInnerHTML={{ __html: formContent?.form_button?.value }}></span>
                        <div className="inline-loading">
                            <div className="lds-ring">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    </button>
                </div>
            </form>
        </section>
    );
}

export default RegisterForm;
