import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Api from '../../api';
import Brand from '../../components/Brand';
import Footer from '../../components/Footer';
import PageSlug from '../../components/PageSlug';
import SocialShares from '../../components/SocialShares/SocialShares';
import WhatsappButton from '../../components/WhatsappButton';
import routeNames from '../../constants/routeNames';
import { ContentContext } from '../../contexts/ContentContext';
import './AlreadyRedeemedPage.css';

export default function AlreadyRedeemed({ location }) {
    const { content } = useContext(ContentContext);
    const s = content?.pages?.errors_list?.settings;
    const c = content?.pages?.errors_list?.content;
    const g = content?.global_settings?.images;
    const showSocials = s?.footer_social;
    const whatsapp = s?.whatsapp;

    const [title, setTitle] = useState(c?.already_redeemed_limit_title?.value || '');
    const [subtitle, setSubtitle] = useState(c?.already_redeemed_limit_description?.value || '');
    const [desc, setDesc] = useState('');

  

    useEffect(() => {
        window.scrollTo({ top: 0 });
        if (location?.state?.type && location?.state?.type !== 'TypeError') {
            setTitle(location?.state?.title);
            setDesc(location?.state?.desc);
        }

        // eslint-disable-next-line
    }, [location,c]);
    return (
        <div
            className={`page-wrapper ${s?.bg && g?.bg ? 'has-bg' : ''}`}
            style={{ backgroundImage: s?.bg && g?.bg && `url(${g?.bg})` }}
        >
            {s?.header && g?.header && <Brand image={g?.header} type={location?.state?.type} />}
            <main>
                {s?.logo && g?.logo && (
                    <section className="campaign-logo">
                        {location?.state?.type === 103 ?
                            (<div className="brand-logo">
                                <img
                                    className="header-title-image"
                                    alt=""
                                    src={g?.logo}
                                />
                            </div>) :
                            (<Link to={routeNames.HOME} className="brand-logo">
                                <img
                                    className="header-title-image"
                                    alt=""
                                    src={g?.logo}
                                />
                            </Link>)
                        }
                    </section>
                )}

                <section
                    className={`main-content ${s?.bg && g?.bg ? 'transparent-container' : ''}`}
                >
                    <PageSlug title={title} />
                    <div className="redeem-instructions" style={{ margin: '0 0 0 0' }}>
                        {subtitle && <p>{subtitle}</p>}
                        {desc && <p>{desc}</p>}
                    </div>
                    {s?.body && <img className="mb-n mt-n body-img" alt="" src={g?.body} />}

                </section>

                <section className={'transparent-container ' + (!showSocials && !whatsapp ? 'hidden' : '')}>
                    {showSocials && (<SocialShares></SocialShares>)}
                    {whatsapp && <WhatsappButton />}
                </section>

                {s?.footer && (
                    <Footer className={`${s?.bg && g?.bg ? 'transparent-container' : ''}`} />
                )}
            </main>
        </div>
    );
}
