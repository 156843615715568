import React, { useEffect, useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Api from '../../api/';
import PageSlug from '../../components/PageSlug';
import LocationInterface from '../../components/LocationInterface';
import './RegisterVenuePage.css';
import { LoadingContext } from '../../contexts/LoadingContext';
import { LocationContext } from '../../contexts/LocationContext';
import routeNames from '../../constants/routeNames';
import Brand from '../../components/Brand';
import Footer from '../../components/Footer';
import { ContentContext } from '../../contexts/ContentContext';
import SocialShares from '../../components/SocialShares/SocialShares';
import WhatsappButton from '../../components/WhatsappButton';

function RegisterVenuePage({ location }) {
    const history = useHistory();
    const { loading, setLoading } = useContext(LoadingContext);
    const { setSelectedLocation } = useContext(LocationContext);
    const [modalVisible, setModalVisible] = useState(null);
    const [csl, setCSL] = useState(null);
    const currentlySelected = { csl, setCSL };

    const { content } = useContext(ContentContext);

    const s = content?.pages?.select_location?.settings;
    const c = content?.pages?.select_location?.content;
    const generic = content?.pages.select_location?.generic;
    const g = content?.global_settings?.images;
    const showSocials = s?.footer_social;
    const whatsapp = s?.whatsapp;

    useEffect(() => {
        window.scrollTo({ top: 0 });
        setLoading(false);

        if (!location.state) {
            setLoading(true);
            history.push(routeNames.HOME);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const registerUser = async () => {
        if (!csl) {
            alert('Please select a location');
            return;
        }
        let btn = document.getElementById("lc-btn");
        btn.style.pointerEvents = 'none';
        btn.classList.add("loading");
        let data = { ...location.state, venueId: csl._id };
        let registerData = await Api.register(data);
        setSelectedLocation(csl);
        if (registerData.result) history.push(`${routeNames.SUCCESS}/${csl._id}`, data);
        else history.push(`${routeNames.SUCCESS}/${csl._id}`, { error: registerData.error });
    };

    const clearSelectedLocation = () => {
        currentlySelected.setCSL(null);
        setModalVisible(false);
    };

    let locationConfirmFunction = registerUser;
    return (
        <div
            className={`page-wrapper ${s?.bg && g?.bg ? 'has-bg' : ''}`}
            style={{ backgroundImage: s?.bg && g?.bg && `url(${g?.bg})` }}
        >
            {s?.header && g?.header && <Brand image={g?.header} />}
            <main>
                {s?.logo && g?.logo && (
                    <section className="campaign-logo">
                        <Link to={routeNames.HOME} className="brand-logo">
                            <img
                                className="header-title-image"
                                alt=""
                                src={g?.logo}
                            />
                        </Link>
                    </section>
                )}
                <section className={`${s?.bg && g?.bg ? 'transparent-container' : ''}`}>
                    <PageSlug title={c?.title?.value} brand={true}>
                        <div className="narrow-info-container">
                            <p dangerouslySetInnerHTML={{ __html: c?.description?.value }}></p>
                        </div>
                    </PageSlug>
                    {/* <p>
                        <b>
                            Select the outlet of your choice below. Please note, products in your
                            chosen outlet are subject to availability.
                        </b>
                    </p> */}
                </section>

                <section
                    className={`main-content ${s?.bg && g?.bg ? 'transparent-container' : ''}`}
                >
                    <LocationInterface
                        currentlySelected={currentlySelected}
                        mapClickable={true}
                        locationConfirmFunction={locationConfirmFunction}
                        modalVisible={modalVisible}
                        setModalVisible={setModalVisible}
                    />
                </section>
                <section className={'transparent-container ' + (!showSocials && !whatsapp ? 'hidden' : '')}>

                    {showSocials && (<SocialShares></SocialShares>)}

                    {whatsapp && <WhatsappButton />}
                </section>

                {s?.footer && (
                    <Footer className={`${s?.bg && g?.bg ? 'transparent-container' : ''}`} />
                )}
            </main>

            {modalVisible && (
                <div className="modal-wrapper">
                    <div className="modal">
                        <span className="modal-close" onClick={clearSelectedLocation}>
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="var(--primary_text)" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.7 0.3C13.3 -0.1 12.7 -0.1 12.3 0.3L7 5.6L1.7 0.3C1.3 -0.1 0.7 -0.1 0.3 0.3C-0.1 0.7 -0.1 1.3 0.3 1.7L5.6 7L0.3 12.3C-0.1 12.7 -0.1 13.3 0.3 13.7C0.5 13.9 0.7 14 1 14C1.3 14 1.5 13.9 1.7 13.7L7 8.4L12.3 13.7C12.5 13.9 12.8 14 13 14C13.2 14 13.5 13.9 13.7 13.7C14.1 13.3 14.1 12.7 13.7 12.3L8.4 7L13.7 1.7C14.1 1.3 14.1 0.7 13.7 0.3Z" fill="var(--primary_text)" />
                            </svg>                        </span>
                        <h3>{currentlySelected.csl.name}</h3>
                        <p>{currentlySelected.csl.formattedAddress}</p>
                        <button className="btn btn-brand" id="lc-btn" onClick={locationConfirmFunction} >
                            <span dangerouslySetInnerHTML={{ __html: generic?.select_location_popup_button_text.value }}></span>
                            <div className="inline-loading">
                                <div className="lds-ring">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </div>
                        </button>

                    </div>
                </div>
            )}
        </div>
    );
}

export default RegisterVenuePage;
