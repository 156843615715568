import React, { useContext } from 'react';
import PageSlug from './PageSlug';
import { ContentContext } from '../contexts/ContentContext';
import Brand from '../components/Brand';
import Footer from '../components/Footer';
import SocialShares from '../components/SocialShares/SocialShares';
import WhatsappButton from '../components/WhatsappButton';
import { Link } from 'react-router-dom';
import routeNames from '../constants/routeNames';


export default function MobileOnly() {
    const { content } = useContext(ContentContext);
    const c = content?.pages?.mobile_only?.content;
    const s = content?.pages?.mobile_only?.settings;
    const g = content?.global_settings?.images;
    const showSocials = s?.footer_social;
    const whatsapp = s?.whatsapp;



    return (
        <>


            <div
                className={`page-wrapper ${s?.bg && g?.bg ? 'has-bg' : ''}`}
                style={{ backgroundImage: s?.bg && g?.bg && `url(${g?.bg})` }}
            >
                {s?.header && g?.header && <Brand image={g?.header} />}
                <main>
                    {s?.logo && g?.logo && (
                        <section className="campaign-logo">
                            <Link to={routeNames.HOME} className="brand-logo">
                                <img
                                    className="header-title-image"
                                    alt=""
                                    src={g?.logo}
                                />
                            </Link>
                        </section>
                    )}
                    <section
                    className={`main-content ${s?.bg && g?.bg ? 'transparent-container' : ''}`}
                >
                    <section className="section-lite text-center card section-padding-small">
                        <PageSlug title={c?.title?.value}>
                            {/* <img src={require('../assets/images/mobile-phone.svg')} width="30" alt="" style={{ marginBottom: 30 }} /> */}
                            <svg version="1.1" id="Icons" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                viewBox="0 0 512 512" style={{ width: '50px', marginBottom: '30px', enableBackground: 'new 0 0 512 512', fill: 'var(--primary_text)' }} xmlSpace="preserve">
                                <g>
                                    <path d="M370.2,13.8H141.8c-15.3,0-27.7,12.4-27.7,27.7v429c0,15.3,12.4,27.7,27.7,27.7h228.3c15.3,0,27.7-12.4,27.7-27.7v-429
                            C397.8,26.2,385.4,13.8,370.2,13.8z M128,89.9h256v304.4H128V89.9z M141.8,27.7h228.3c7.6,0,13.8,6.2,13.8,13.8v34.6H128V41.5
                            C128,33.9,134.2,27.7,141.8,27.7z M370.2,484.3H141.8c-7.6,0-13.8-6.2-13.8-13.8v-62.3h256v62.3C384,478.1,377.8,484.3,370.2,484.3
                            z"/>
                                    <path d="M256,420.8c-14.1,0-25.5,11.4-25.5,25.5c0,14.1,11.4,25.5,25.5,25.5c14.1,0,25.5-11.4,25.5-25.5
                            C281.5,432.2,270.1,420.8,256,420.8z M267.7,446.3c0,6.5-5.2,11.7-11.7,11.7c-6.5,0-11.7-5.2-11.7-11.7s5.2-11.7,11.7-11.7
                            C262.4,434.6,267.7,439.8,267.7,446.3C267.7,446.3,267.7,446.3,267.7,446.3z"/>
                                    <path d="M228.3,60.5h55.4c3.8,0,6.9-3.1,6.9-6.9c0-3.8-3.1-6.9-6.9-6.9h-55.4c-3.8,0-6.9,3.1-6.9,6.9
                            C221.4,57.4,224.5,60.5,228.3,60.5z"/>
                                </g>
                            </svg>

                            <p dangerouslySetInnerHTML={{ __html: c?.description?.value }}></p>
                        </PageSlug>
                    </section>
                    </section>
                    <section
                        className={
                            'transparent-container ' + (!showSocials && !whatsapp ? 'hidden' : '')
                        }
                    >
                        {showSocials && <SocialShares></SocialShares>}
                        {whatsapp && <WhatsappButton />}
                    </section>
                    {s?.footer && (
                        <Footer className={`${s?.bg && g?.bg ? 'transparent-container' : ''}`} />
                    )}
                </main>
            </div>
        </>
    );
}
