import React, { useContext, useEffect } from 'react';
import { useRouteMatch } from 'react-router';
import { LoadingContext } from '../../contexts/LoadingContext';
import PageSlug from '../../components/PageSlug';
import './SignUpMessagePage.css';
import routeNames from '../../constants/routeNames';
import Brand from '../../components/Brand';
import Footer from '../../components/Footer';
import { ContentContext } from '../../contexts/ContentContext';
import SocialShares from '../../components/SocialShares/SocialShares';
import WhatsappButton from '../../components/WhatsappButton';
import { Link } from 'react-router-dom';

export default function SignUpMessagePage({ location }) {
    const match = useRouteMatch(`${routeNames.SUCCESS}/:uniqueId`);
    const { content } = useContext(ContentContext);
    const {loading, setLoading} = useContext(LoadingContext);
    const s = content?.pages?.thank_you?.settings;
    const c = content?.pages?.thank_you?.content;
    const g = content?.global_settings?.images;
    const showSocials = s?.footer_social;
    const whatsapp = s?.whatsapp;

    useEffect(() => {
        window.scrollTo({ top: 0 });
        setLoading(false);


       
        // eslint-disable-next-line
    }, []);

    return (
        <div
            className={`page-wrapper ${s?.bg && g?.bg ? 'has-bg' : ''}`}
            style={{ backgroundImage: s?.bg && g?.bg && `url(${g?.bg})` }}
        >
            {s?.header && g?.header && <Brand image={g?.header} />}
            <main>
                {s?.logo && g?.logo && (
                    <section className="campaign-logo">
                        <Link to={routeNames.HOME} className="brand-logo">
                            <img
                                className="header-title-image"
                                alt=""
                                src={g?.logo}
                            />
                        </Link>
                    </section>
                )}
                {match && location?.state?.error ? (
                    <>
                        <section
                            className={`main-content text-center mb-n ${s?.bg && g?.bg ? 'transparent-container' : ''
                                }`}
                        >
                            <PageSlug title={location.state?.error} />
                            <div className="redeem-instructions">
                                <p dangerouslySetInnerHTML={{ __html: c?.already_signed_up_error?.value }}></p>
                            </div>
                            {s?.body && <img className="mt-n body-img" src={g?.body} alt="" />}
                        </section>
                    </>
                ) : (
                    <>
                        <section
                            className={`main-content ${s?.bg && g?.bg ? 'transparent-container' : ''
                                }`}
                        >
                            <PageSlug title={c?.title?.value} />
                            <div className="redeem-instructions">
                                <p dangerouslySetInnerHTML={{ __html: c?.description?.value }}>
                                </p>
                                <p dangerouslySetInnerHTML={{ __html: c?.check_email?.value }}></p>
                                <small style={{ fontStyle: 'italic' }}
                                    dangerouslySetInnerHTML={{ __html: c?.check_email_warning?.value }}>

                                </small>
                                <p className="faq-links" dangerouslySetInnerHTML={{ __html: c?.voucher_notice?.value }}></p>
                            </div>
                            {s?.body && <img className="mt-n body-img" src={g?.body} alt="" />}
                        </section>
                    </>
                )}

                <section className={'transparent-container ' + (!showSocials && !whatsapp ? 'hidden' : '')}>
                    {showSocials && (<SocialShares></SocialShares>)}
                    {whatsapp && <WhatsappButton />}
                </section>

                {s?.footer && (
                    <Footer className={`${s?.bg && g?.bg ? 'transparent-container' : ''}`} />
                )}
            </main>
        </div>
    );
}
