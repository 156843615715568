let routeNames = {
    HOME: '/',
    SIGN_UP: '/sign-up',
    SUCCESS: '/success',
    VENUES: '/venues',
    VENUE: '/venue',
    ALREADY_REDEEMED: '/already-redeemed',
    PROMOTION_CLOSED: '/closed',
    LOCATION_CHECK: '/location-check',
    COOKIE_POLICY: '/cookie-policy',
    PRIVACY_POLICY: '/privacy-policy',
    PROMOTION_TERMS: '/promotion-terms',
    TERMS_CONDITIONS: '/terms',
    REDEEM: '/redeem',
    VOUCHER: '/voucher',
    REDEEM_SUCCESS: '/redeem-success',
    RESEND_EMAIL: '/resend-email',
    FAQ: '/faqs',
    LOCATION_ERROR: '/location-error',
    NOT_AVAILABLE: '/not-available',
    NOT_AVAILABLE_DAILY_LIMIT: '/not-available/daily-limit',
    NOT_AVAILABLE_RETAILER_LIMIT : '/not-available/retailer-limit',
    NOT_AVAILABLE_CAMPAIGN_LIMIT : '/not-available/campaign-limit',
    NOT_AVAILABLE_VENUE_OFF : '/not-available/venue-closed',
    NOT_AVAILABLE_RETAILER_OFF : '/not-available/retailer-closed',
    NO_LOCATION: '/no-location',
    ALREADY_SIGNED_UP : "/already-signed-up"
};

export default routeNames;
