import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router';
import Api from '../../api/Api';
import './SwipeToRedeem.css';
import routeNames from '../../constants/routeNames';
import { ContentContext } from '../../contexts/ContentContext';
import routeHelper from '../../helpers/routeHelper';
import { useSwipeable } from 'react-swipeable'

export default function SwipeToRedeem({ unique, type, venueId, title }) {
    const history = useHistory();
    const { content } = useContext(ContentContext);
    const [swiped, setSwiped] = useState(false);
    const sw = content?.global_settings?.swipe;
    const [handPosition, sethandPosition] = useState(0)



    const handlers = useSwipeable({

        onSwiped: (eventData) => {
            document.body.style.overflow = "auto";

            let sliderHand = document.getElementById("sliderHand").clientWidth;
            let positiveIcon = document.getElementById("positiveIcon");
            if (handPosition > 140) {
                positiveIcon.style.clipPath = `inset(0 0 0 0)`;
                checkSwipe(handPosition);
            } else {
                positiveIcon.style.clipPath = `inset(0 93% 0 0)`;
                sethandPosition(0);
            }
        },
        onSwiping: (eventData) => {
            let positiveIcon = document.getElementById("positiveIcon");
            let sliderHand = document.getElementById("sliderHand").clientWidth;
            if (eventData.dir == "Right") {
                if (eventData.deltaX * 2 <= sliderHand) {
                    sethandPosition(eventData.deltaX * 2);
                }
                let position = 100 - eventData.deltaX;
                positiveIcon.style.clipPath = `inset(0 ${position}% 0 0)`;
            }   
            if (eventData.dir == "Up" || eventData.dir == "Down") {
                document.body.style.overflow = "hidden";
            }
        }
    });


    const handleSwipe = (e) => {
        const positiveIcon = document.getElementById("positiveIcon");
        const size = 100 - e.target.value;
        positiveIcon.style.clipPath = `inset(0 ${size}% 0 0)`;
        checkSwipe(e.target.value);
    }




    const checkSwipe = (size) => {

        const hand = document.getElementById("swipeInput") ? document.getElementById("swipeInput") : document.getElementById("sliderHand");
        const positiveIcon = document.getElementById("positiveIcon");
        if (size > 150) {
            setTimeout(async () => {
                positiveIcon.style.transition = '0.1s';
                positiveIcon.style.clipPath = `inset(0 0 0 0)`;
                hand.style.display = "none";
                hand.style.transition = '0.1s';
                setSwiped(true);
            }, 1000);
        }
    };

    useEffect(() => {

    }, [type, unique, venueId]);

    useEffect(() => {
        (async () => {
            if (swiped && unique && type && venueId) {
                const response = await Api.swipeUniqueCode({
                    code: unique,
                    urlType: type,
                    venueId,
                });
                if (response && response.result) {
                    history.push({
                        pathname: routeNames.VOUCHER,
                        state: { data: response.data, unique, type, venueId },
                    });
                } else {
                    routeHelper(response?.error?.type, content, history,null,response);
                    // history.push(routeNames.ALREADY_REDEEMED, { type: response?.error?.type });
                }
            }
        })();
        // eslint-disable-next-line
    }, [swiped]);

    return (
        <div className="d-flex flex-column">

            <section className="swipe-section">
                <h2 className="swipe-text">{title}</h2>
                <div className="swipe-img-section">

                    {(sw?.swipe_type == true) && (
                        <>
                            <img src={sw?.negative_swipe} className="negativeIcon" id="negativeIcon" alt="negative"></img>
                            <img src={sw?.positive_swipe} className="positiveIcon" id="positiveIcon" alt="positive"></img>
                            {/* <input type="range" id="swipeInput" name="swipeInput" defaultValue="0" min={0} max={100} onInput={handleSwipe} onChange={handleSwipe} placeholder="Swipe to redeem" /> */}
                        </>
                    )}

                    {(sw?.swipe_type == false) && (
                        <>
                            <svg width="305" height="64" viewBox="0 0 305 64" fill={sw?.arrow_color} xmlns="http://www.w3.org/2000/svg" className="negativeIcon">
                                <path d="M261 7.5L297.8 32L261 56.5V48V44H257H4V20H257H261V16V7.5ZM257 0V16H0V48H257V64L305 32L257 0Z" fill={sw?.arrow_color} />
                            </svg>
                            <svg width="305" height="64" viewBox="0 0 305 64" xmlns="http://www.w3.org/2000/svg" className="positiveIcon" id="positiveIcon">
                                <g>
                                    <path id="svg_1" fill={sw?.arrow_color} d="m261,7.5l36.8,24.5l-36.8,24.5l0,-8.5l0,-4l-4,0l-253,0l0,-24l253,0l4,0l0,-4l0,-8.5zm-4,-7.5l0,16l-257,0l0,32l257,0l0,16l48,-32l-48,-32z" />
                                    <rect id="svg_2" height="27.40214" width="258" y="17.58719" x="2.66014" fill={sw?.arrow_color} stroke="null" />
                                    <path transform="rotate(89.9693 280.725 32.0749)" stroke="0px" id="svg_3" d="m254.56747,52.71549l26.15726,-41.28114l26.15726,41.28114l-52.31452,0z" fill={sw?.arrow_color} />
                                </g>
                            </svg>
                        </>
                    )}
                    <div id="sliderHand">
                        <svg width="100%" xmlns="http://www.w3.org/2000/svg" version="1.1">

                            <clipPath id="myClip"></clipPath>
                            <g id="hand" {...handlers}>
                                <path id="movableHand" stroke={sw?.stroke_color} transform={`translate(${handPosition},0)`} strokeWidth="4px" d="M99.9002 98.6999C99.9002 93.5999 100.8 91.4999 100.1 84.9999C99.4002 78.4999 91.9002 59.6999 91.9002 59.6999L86.6002 45.2999C86.1002 43.9999 85.4002 42.7999 84.5002 41.6999C82.8002 39.8999 81.0002 39.1999 79.3002 39.1999C78.7002 39.1999 78.0002 39.2999 77.5002 39.4999L76.9002 39.6999C74.3002 40.5999 73.5002 43.5999 74.5002 46.5999L73.1002 42.7999C72.7002 41.5999 72.1002 40.4999 71.2002 39.5999C69.3002 37.4999 67.5002 36.3999 65.3002 36.3999C64.7002 36.3999 64.0002 36.4999 63.2002 36.6999C59.1002 38.1999 57.0002 40.0999 59.6002 48.6999L57.5002 42.7999C56.8002 40.8999 55.6002 39.1999 53.9002 38.0999C52.4002 37.0999 50.8002 36.5999 49.3002 36.5999C48.6002 36.5999 47.9002 36.6999 47.4002 36.8999C43.4002 38.2999 41.3002 40.8999 42.3002 45.6999L43.3002 50.3999L29.1002 17.3999L27.8002 14.4999L26.5002 11.5999L25.0002 8.09991C23.3002 4.69991 21.0002 3.09991 18.4002 3.09991C17.5002 3.09991 16.5002 3.29991 15.6002 3.59991C12.2002 4.79991 10.5002 7.59991 10.9002 11.4999C11.0002 11.9999 11.1002 12.5999 11.2002 13.0999L11.6002 14.3999L12.5002 17.2999L31.7002 79.0999H31.5002C31.5002 79.0999 23.6002 74.0999 18.0002 72.8999C16.8002 72.5999 15.7002 72.4999 14.6002 72.4999C7.90019 72.4999 3.70019 77.0999 2.50019 80.0999C2.00019 81.2999 2.40019 82.6999 3.50019 83.4999C6.20019 85.4999 11.6002 89.5999 15.1002 93.5999C20.0002 99.1999 28.9002 108.7 36.1002 112.4C43.3002 116.1 45.8002 117.3 50.7002 122.7C53.0002 125.2 54.7002 127.4 55.8002 129C56.6002 130.2 58.0002 130.9 59.4002 130.9C59.9002 130.9 60.4002 130.8 60.9002 130.6L99.2002 116.6C101.7 115.7 103.1 113 102.3 110.4C101.2 106.9 99.9002 101.7 99.9002 98.6999Z" fill={sw?.hand_color} />
                            </g>
                            <use clipPath="url(#myClip)" href="#movableHand" />
                        </svg>
                    </div>
                </div>
            </section>
        </div>
    );
}
