import React, { useEffect, useContext, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { Link, useHistory } from 'react-router-dom';
import { LocationContext } from '../../contexts/LocationContext';
import Api from '../../api/';
import PageSlug from '../../components/PageSlug';
import LocationInterface from '../../components/LocationInterface';
import isMobile from '../../helpers/detectMobile';
import MobileOnly from '../../components/MobileOnly';
import './VenuesPage.css';
import routeNames from '../../constants/routeNames';
import Brand from '../../components/Brand';
import Footer from '../../components/Footer';
import { ContentContext } from '../../contexts/ContentContext';
import SocialShares from '../../components/SocialShares/SocialShares';
import WhatsappButton from '../../components/WhatsappButton';

function VenuesPage({ location }) {
    const history = useHistory();
    const match = useRouteMatch(`${routeNames.VENUES}/:unique/:type/`);
    const { selectedLocation, setSelectedLocation, setNearbyLocations } = useContext(
        LocationContext
    );
    const [modalVisible, setModalVisible] = useState(null);
    const [pageState, setPageState] = useState(null); // [selectDifferent, register, none]
    const [isLoading, setIsLoading] = useState(true);
    const [csl, setCSL] = useState(null);

    const currentlySelected = { csl, setCSL };

    const { content } = useContext(ContentContext);
    const s = content?.pages?.new_location?.settings;
    const c = content?.pages?.new_location?.content;
    const g = content?.global_settings?.images;
    const showSocials = s?.footer_social;
    const whatsapp = s?.whatsapp;

    useEffect(() => {
        window.scrollTo({ top: 0 });
    }, []);

    useEffect(() => {
        if (match) {
            setPageState('selectDifferent');
            setIsLoading(false);
        } else if (location.state !== undefined) {
            setIsLoading(false);
            setPageState('register');
        } else {
            setPageState('none');
            history.push(routeNames.HOME);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageState]);

    const registerUser = async () => {
        let data = { ...location.state, venueId: selectedLocation._id };
        let registerData = await Api.register(data);
        if (registerData.result) history.push(`${routeNames.SUCCESS}/${selectedLocation._id}`);
        else
            history.push(`${routeNames.SUCCESS}/${selectedLocation._id}`, {
                error: registerData.error,
            });
    };

    const clearSelectedLocation = () => {
        currentlySelected.setCSL(null);
        setModalVisible(false);
    };

    const locationCheck = () => {
        setNearbyLocations([csl || selectedLocation]);
        let unique;
        let type;

        if (pageState === 'selectDifferent') {
            setModalVisible(false);
            unique = match.params.unique;
            type = match.params.type;
        } else if (pageState === 'register') {
            unique = location.state.unique;
            type = location.state.type;
        }

        (async () => {
            setSelectedLocation(csl || selectedLocation);
            await Api.setSelectedVenue(unique, csl ? csl._id : selectedLocation._id);
        })();

       history.push(`${routeNames.LOCATION_CHECK}/${unique}/${type}`);
    };

    let locationConfirmFunction;
    if (pageState === 'register') locationConfirmFunction = registerUser;
    else locationConfirmFunction = locationCheck;

    useEffect(() => {
        if (pageState === 'selectDifferent') {
            (async function checkConfirmation() {
    
                const uniqueResponse = await Api.checkUnique(match.params.unique);
                let selectedVenue = await Api.getSelectedVenue(match.params.unique);
                if (uniqueResponse && uniqueResponse.data && uniqueResponse.data.swiped) {
                    history.push(routeNames.REDEEM, {
                        unique: match.params.unique,
                        type: match.params.type,
                        venueId: selectedVenue.data._id,
                        firstTime: false,
                        uniqueData: uniqueResponse.data,
                    });
                }
                if (uniqueResponse && uniqueResponse.result) {
                    !selectedLocation && selectedVenue && setSelectedLocation(selectedVenue.data);
                    setModalVisible(false);
                    setIsLoading(false);
                } else {
                    history.push(routeNames.ALREADY_REDEEMED, {
                        type: uniqueResponse?.error?.type,
                    });
                }
            })();
        }
        // eslint-disable-next-line
    }, [pageState]);

    if (!isMobile && match) {
        return <MobileOnly />;
    }

    return (
        <div
            className={`page-wrapper ${s?.bg && g?.bg ? 'has-bg' : ''}`}
            style={{ backgroundImage: s?.bg && g?.bg && `url(${g?.bg})` }}
        >
            {s?.header && g?.header && <Brand image={g?.header} />}
            {s?.logo && g?.logo && (
                    <section className="campaign-logo">
                        <Link to={routeNames.HOME} className="brand-logo">
                            <img
                                className="header-title-image"
                                alt=""
                                src={g?.logo}
                            />
                        </Link>
                    </section>
                )}
            {isLoading ? (
                <div />
            ) : (
                <main className="container-large ">
                    {pageState === 'register' ? (
                        <div className="transparent-wrapper-top">
                            <PageSlug title={c?.new_location_register_title?.value} brand={true}>
                                <div className="narrow-info-container">
                                    <p dangerouslySetInnerHTML={{ __html: c?.new_location_register_description?.value }}></p>
                                </div>
                            </PageSlug>
                        </div>
                    ) : (
                        <div className="transparent-wrapper-top">
                            <PageSlug title={c?.new_location_title?.value} brand={true}>
                                <div className="narrow-info-container">

                                    <p dangerouslySetInnerHTML={{ __html: c?.new_location_description?.value }}></p>

                                </div>
                            </PageSlug>

                            {selectedLocation && (
                                <section className="section-lite text-center card location-result">
                                    <h3>{selectedLocation.name}</h3>
                                    <p>{selectedLocation.formattedAddress}</p>
                                </section>
                            )}

                            <section className="current-venue">
                                <p
                                    dangerouslySetInnerHTML={{ __html: c?.select_new_or_keep_old?.value }}
                                ></p>
                                <button
                                    className="btn btn-brand btn-wide btn-uppercase"
                                    onClick={() => {
                                        locationCheck(false);
                                        // if (!match) {
                                        // history.goBack();
                                        // }
                                    }}
                                >
                                    <span dangerouslySetInnerHTML={{ __html: c?.button_text?.value }}></span>
                                </button>
                            </section>
                        </div>
                    )}

                    <div className="transparent-wrapper-bottom location-container">
                        <LocationInterface
                            currentlySelected={currentlySelected}
                            mapClickable={true}
                            locationConfirmFunction={locationConfirmFunction}
                            modalVisible={modalVisible}
                            setModalVisible={setModalVisible}
                        />
                    </div>

                    <section className={'transparent-container ' + (!showSocials && !whatsapp ? 'hidden' : '')}>
                        {showSocials && (<SocialShares></SocialShares>)}
                        {whatsapp && <WhatsappButton />}
                    </section>

                    {s?.footer && (
                        <Footer className={`${s?.bg && g?.bg ? 'transparent-container' : ''}`} />
                    )}
                </main>

            )}
            {modalVisible && (
                <div className="modal-wrapper">
                    <div className="modal">
                        <span className="modal-close" onClick={clearSelectedLocation}>
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="var(--primary_text)" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.7 0.3C13.3 -0.1 12.7 -0.1 12.3 0.3L7 5.6L1.7 0.3C1.3 -0.1 0.7 -0.1 0.3 0.3C-0.1 0.7 -0.1 1.3 0.3 1.7L5.6 7L0.3 12.3C-0.1 12.7 -0.1 13.3 0.3 13.7C0.5 13.9 0.7 14 1 14C1.3 14 1.5 13.9 1.7 13.7L7 8.4L12.3 13.7C12.5 13.9 12.8 14 13 14C13.2 14 13.5 13.9 13.7 13.7C14.1 13.3 14.1 12.7 13.7 12.3L8.4 7L13.7 1.7C14.1 1.3 14.1 0.7 13.7 0.3Z" fill="var(--primary_text)" />
                            </svg>
                        </span>
                        <h3>{currentlySelected.csl.name}</h3>
                        <p>{currentlySelected.csl.formattedAddress}</p>
                        <button className="btn btn-brand" onClick={locationConfirmFunction}>
                            Select
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default VenuesPage;
