import React, { useState, useEffect, useContext } from 'react';
import ResendEmailForm from '../../components/ResendEmailForm';
import PageSlug from '../../components/PageSlug';
import routeNames from '../../constants/routeNames';
import { useHistory, Link } from 'react-router-dom';
import Brand from '../../components/Brand';
import Footer from '../../components/Footer';
import { ContentContext } from '../../contexts/ContentContext';
import SocialShares from '../../components/SocialShares/SocialShares';
import WhatsappButton from '../../components/WhatsappButton';

function ResendEmailPage() {
    const history = useHistory();
    const [resendSuccess, setResendSuccess] = useState(null);

    const { content } = useContext(ContentContext);
    const s = content?.pages?.resend_email?.settings;
    const c = content?.pages?.resend_email?.content;
    const generic = content?.pages?.resend_email?.generic;
    const g = content?.global_settings?.images;
    const showSocials = s?.footer_social;
    const whatsapp = s?.whatsapp;

    useEffect(() => {
        if (
            resendSuccess &&
            resendSuccess.result === false &&
            resendSuccess.error === 'Already Redeemed!'
        ) {
            history.push(routeNames.ALREADY_REDEEMED);
        }
        // eslint-disable-next-line
    }, [resendSuccess]);

    return (
        <div
            className={`${s?.bg && g?.bg ? 'has-bg' : ''} page-wrapper`}
            style={{ backgroundImage: s?.bg && g?.bg && `url(${g?.bg})` }}
        >
            {s?.header && g?.header && <Brand image={g?.header} />}
            <main>
                {s?.logo && g?.logo && (
                    <section className="campaign-logo">
                        <Link to={routeNames.HOME} className="brand-logo">
                            <img
                                className="header-title-image"
                                alt=""
                                src={g?.logo}
                            />
                        </Link>
                    </section>
                )}
                {resendSuccess === null && (
                    <>
                        <section
                            className={`main-content ${s?.bg && g?.bg ? 'transparent-container' : ''}`}
                        >
                            <PageSlug title={c?.title?.value} />
                            <div className="content">
                                <p dangerouslySetInnerHTML={{ __html: c?.description?.value }}></p>
                                <p>
                                    <b dangerouslySetInnerHTML={{ __html: c?.enter_email?.value }}></b>
                                </p>
                            </div>
                            <ResendEmailForm setResendSuccess={setResendSuccess} content={generic}></ResendEmailForm>
                            {s?.body && <img className="mb-n mt-n body-img" alt="" src={g?.body} />}

                        </section>
                    </>
                )}

                {resendSuccess && resendSuccess.error === 'Email not Exists' && (
                    <>
                        <section
                            className={`main-content ${s?.bg && g?.bg ? 'transparent-container' : ''}`}
                        >
                            <PageSlug title={generic?.email_not_found_error_title?.value} />
                            <div className="content">
                                <p dangerouslySetInnerHTML={{ __html: generic?.email_not_found_error_description?.value }}></p>
                                <p>
                                    <Link to={routeNames.HOME} dangerouslySetInnerHTML={{ __html: c?.sign_up_here_first_paragraph?.value }}></Link>{' '}
                                    <span dangerouslySetInnerHTML={{ __html: c?.sign_up_here_second_paragraph?.value }}></span>
                                </p>
                            </div>
                        </section>
                    </>
                )}
                {resendSuccess && resendSuccess.result === true && (
                    <>
                        <section
                            className={`main-content ${s?.bg && g?.bg ? 'transparent-container' : ''}`}
                        >
                            <PageSlug title={c?.email_sent_title?.value} />
                            <div className="content">
                                <p dangerouslySetInnerHTML={{ __html: c?.email_sent_description?.value }}></p>

                            </div>
                        </section>
                    </>
                )}

                {resendSuccess && resendSuccess.error === 'Already Sent!' && (
                    <>
                        <section
                            className={`main-content ${s?.bg && g?.bg ? 'transparent-container' : ''}`}
                        >
                            <PageSlug title={c?.email_resent_title?.value} />
                            <div className="content">
                                <span dangerouslySetInnerHTML={{ __html: c?.email_resent_content_first_paragraph?.value }}></span>
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: c?.email_resent_description?.value,
                                    }}
                                ></span>
                            </div>
                        </section>
                    </>
                )}
                <section className={'transparent-container ' + (!showSocials && !whatsapp ? 'hidden' : '')}>
                    {showSocials && (<SocialShares></SocialShares>)}
                    {whatsapp && <WhatsappButton />}
                </section>
                {s?.footer && (
                    <Footer className={`${s?.bg && g?.bg ? 'transparent-container' : ''}`} />
                )}
            </main>
        </div>
    );
}

export default ResendEmailPage;
