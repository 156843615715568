import AgeConfirmationPage from './pages/AgeConfirmationPage';
import SignUpPage from './pages/SignUpPage';
import VenuesPage from './pages/VenuesPage';
import {
    CookiePolicyPage,
    PrivacyPolicyPage,
    PromotionTermsAndConditionsPage,
    TermsAndConditionsPage,
    FAQPage,
} from './pages/SimplePages/';
import SignUpMessagePage from './pages/SignUpMessagePage';
import AlreadyRedeemedPage from './pages/AlreadyRedeemedPage';
import LocationErrorPage from './pages/LocationErrorPage/LocationErrorPage';
import VoucherPage from './pages/VoucherPage/VoucherPage';
import RedeemSuccessPage from './pages/RedeemSucessPage/RedeemSucessPage';
import RegisterVenuePage from './pages/RegisterVenuePage';
import RedeemPage from './pages/RedeemPage';
import routeNames from './constants/routeNames';
import ResendEmailPage from './pages/ResendEmailPage';
import CampaignClosedPage from './pages/CampaignClosedPage';
import NotAvailablePage from './pages/NotAvailablePage'
import NoLocationPage from './pages/NoLocationPage';
import AlreadySignedUpPage from './pages/AlreadySignedUpPage/AlreadySignedUpPage';
import LocationCheckPage from './pages/LocationCheckPage/LocationCheckPage';

let Routes = [
    {
        path: routeNames.HOME,
        name: 'Age Gate',
        component: AgeConfirmationPage,
        exact: true,
        availableOnDesktop: true,
    },
    {
        path: routeNames.SIGN_UP,
        name: 'Homepage',
        component: SignUpPage,
        exact: true,
        availableOnDesktop: true,
    },
    {
        path: routeNames.SUCCESS,
        name: 'Submitted',
        component: SignUpMessagePage,
        exact: false,
        availableOnDesktop: true,
    },
    {
        path: routeNames.VENUES,
        name: 'VenuesPage',
        component: VenuesPage,
        exact: false,
        availableOnDesktop: true,
    },
    {
        path: routeNames.VENUE,
        name: 'Location',
        component: RegisterVenuePage,
        exact: false,
        availableOnDesktop: true,
    },
    {
        path: routeNames.ALREADY_REDEEMED,
        name: 'AlreadyRedeemePage',
        component: AlreadyRedeemedPage,
        exact: false,
        availableOnDesktop: true,
    },
    {
        path: routeNames.LOCATION_ERROR,
        name: 'Location Error',
        component: LocationErrorPage,
        exact: false,
        availableOnDesktop: true,
    },
    {
        path: routeNames.PROMOTION_CLOSED,
        name: 'PromotionClosed',
        component: CampaignClosedPage,
        exact: false,
        availableOnDesktop: true,
    },
    {
        path: routeNames.LOCATION_CHECK,
        name: 'LocationCheckPage',
        component: LocationCheckPage,
        exact: false,
        availableOnDesktop: false,
    },
    {
        path: routeNames.COOKIE_POLICY,
        name: 'CookiePolicyPage',
        component: CookiePolicyPage,
        exact: true,
        availableOnDesktop: true,
    },
    {
        path: routeNames.PRIVACY_POLICY,
        name: 'PrivacyPolicyPage',
        component: PrivacyPolicyPage,
        exact: true,
        availableOnDesktop: true,
    },
    {
        path: routeNames.PROMOTION_TERMS,
        name: 'PromotionTermsAndConditionsPage',
        component: PromotionTermsAndConditionsPage,
        exact: true,
        availableOnDesktop: true,
    },
    {
        path: routeNames.TERMS_CONDITIONS,
        name: 'TermsAndConditionsPage',
        component: TermsAndConditionsPage,
        exact: true,
        availableOnDesktop: true,
    },
    {
        // path: routeNames.VOUCHER,
        path: routeNames.REDEEM,
        name: 'VoucherPage',
        component: VoucherPage,
        exact: true,
        availableOnDesktop: false,
    },
    {
        path: routeNames.VOUCHER,
        name: 'Redeem',
        component: RedeemPage,
        exact: true,
        availableOnDesktop: false,
    },
    {
        path: routeNames.REDEEM_SUCCESS,
        name: 'RedeemSuccessPage',
        component: RedeemSuccessPage,
        exact: true,
        availableOnDesktop: false,
    },
    {
        path: routeNames.RESEND_EMAIL,
        name: 'ResendEmailPage',
        component: ResendEmailPage,
        exact: false,
        availableOnDesktop: true,
    },
    {
        path: routeNames.FAQ,
        name: 'FAQ',
        component: FAQPage,
        exact: false,
        availableOnDesktop: true,
    },
    {
        path: routeNames.NOT_AVAILABLE,
        name: 'Not Available',
        component: NotAvailablePage,
        exact: false,
        availableOnDesktop: true,
    },
    {
        path: routeNames.NOT_AVAILABLE_DAILY_LIMIT,
        name: 'Not Available Daily Limit',
        component: NotAvailablePage,
        exact: false,
        availableOnDesktop: true,
    },
    {
        path: routeNames.NOT_AVAILABLE_RETAILER_LIMIT,
        name: 'Not Available Retailer Limit',
        component: NotAvailablePage,
        exact: false,
        availableOnDesktop: true,
    },
    {
        path: routeNames.NOT_AVAILABLE_CAMPAIGN_LIMIT,
        name: 'Not Available Campaign Limit',
        component: NotAvailablePage,
        exact: false,
        availableOnDesktop: true,
    },
    {
        path: routeNames.NOT_AVAILABLE_VENUE_OFF,
        name: 'Not Available Venue Closed',
        component: NotAvailablePage,
        exact: false,
        availableOnDesktop: true,
    },
    {
        path: routeNames.NOT_AVAILABLE_RETAILER_OFF,
        name: 'Not Available Retailer Closed',
        component: NotAvailablePage,
        exact: true,
        availableOnDesktop: true,
    },
    
    {
        path: routeNames.NO_LOCATION,
        name: 'No Location',
        component: NoLocationPage,
        exact: false,
        availableOnDesktop: true,
    },
    {
        path: routeNames.ALREADY_SIGNED_UP,
        name: 'Already signed up',
        component: AlreadySignedUpPage,
        exact: false,
        availableOnDesktop: true,
    },
    
];

export default Routes;
