import React, { useState, useEffect,useContext } from 'react';
import xIcon from '../../assets/images/x.svg';
import './CookieBar.css';
import { ContentContext } from '../../contexts/ContentContext';

export default function CookieBar() {
    const [cookies, setCookies] = useState(null);
    const { content } = useContext(ContentContext);
    const _cookies = content?.global_settings?.cookies;

    const acceptCookies = () => {
        let gdprCookie = localStorage.setItem('CC_ACCEPTED_COOKIES', new Date());
        setCookies(gdprCookie);
    };

    const checkCookies = () => {
        return localStorage.getItem('CC_ACCEPTED_COOKIES');
    };

    useEffect(() => {
        let gdprCookie = checkCookies();
        gdprCookie && setCookies(gdprCookie);
    }, [cookies]);

    return !cookies ? (
        <div className="cookie-bar content-ready">
            <div className="cookie-bar-content" dangerouslySetInnerHTML={{__html : _cookies?.cookie_text}}>
                
            </div>
            {/* <button className="btn btn-primary" onClick={acceptCookies}>I Agree</button> */}
            <span className="modal-close" onClick={acceptCookies}>
                <img src={xIcon} alt="close" />
            </span>
        </div>
    ) : null;
}
