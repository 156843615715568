import React, { useEffect, useContext } from 'react';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { LocationContext } from '../../contexts/LocationContext';
import './LocationInterface.css';

function LocationInterface() {
    const { setUserLocation, locateMe, setAskForLocation } = useContext(LocationContext);
    const handleSubmit = e => {
        e.preventDefault();
    };

    const locationSelect = async location => {
        let geoLocation = await geocodeByPlaceId(location.place_id);
        setUserLocation({
            lat: geoLocation[0].geometry.location.lat(),
            lng: geoLocation[0].geometry.location.lng(),
        });
    };

    useEffect(() => {
        setAskForLocation(true);
        // eslint-disable-next-line
    }, []);

    return (
        <form className="locaiton-form" onSubmit={handleSubmit}>
            <div className="input-wrapper">
                <button type="button" onClick={locateMe} className="locate-btn btn btn-primary">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path id="locationIcon" d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM9 13.9V12H7V13.9C4.5 13.5 2.5 11.5 2.1 9H4V7H2.1C2.5 4.5 4.5 2.5 7 2.1V4H9V2.1C11.5 2.5 13.5 4.5 13.9 7H12V9H13.9C13.5 11.5 11.5 13.5 9 13.9Z" fill="black" />
                        <path id="locationIcon" d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z" fill="black" />
                    </svg>
                </button>
            </div>

            <div className="input-wrapper">
                <GooglePlacesAutocomplete
                    autocompletionRequest={{ componentRestrictions: { country: 'uk' } }}
                    placeholder="Find Location"
                    onSelect={locationSelect}
                    setComponentRestrictions={'uk'}
                />
                <button type="submit">
                    <i className="fas fa-search magnify"></i>
                </button>
            </div>
        </form>
    );
}

export default LocationInterface;
